import {Code, GraduationCap, Microscope, Palette, Waypoints} from "lucide-react";
//from https://lucide.dev/icons/
export const projects = [
    {
        id: 1,
        title: "AI Chatbot",
        student: "Alice Smith",
        category: "Computer Science",
        icon: Waypoints,
        size: "large",
        description: "An advanced AI chatbot that uses natural language processing to provide human-like conversations and assist with various tasks.",
        image: "/assests/WechatIMG56.jpg",
        color: "orange"

    },
    {
        id: 2,
        title: "Open Source Web builder",
        student: "BobC",
        category: "Computer Science",
        icon: Code,
        size: "small",
        description: "Platform for students to present their work in and beyond Keystone community",
        image: "/assests/WechatIMG56.jpg",
        url: "https://jeremyztest.netlify.app/"
    }
];